@import "../../styles/colors.scss";

.container-privacy-policy {
  display: flex;
  justify-content: center;
  position: relative;
  background: $gradient;

  .content {
    width: 1300px;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 5rem;

      .image {
        width: 17rem;
        height: 5rem;
        background-image: url("../../assets/intuitiva/logo_intuitiva_com_nome_branco.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        cursor: pointer;

        @media only screen and (max-width: 1300px) {
          width: 5rem;
          height: 5rem;
          background-image: url("../../assets/intuitiva/logo_intuitiva_sem_nome_branco.png");
        }
      }
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      margin: 4rem 0rem 8rem 0rem;
      z-index: 2;

      .title {
        font-size: 3rem;
        font-weight: bold;
        color: white;
      }

      .subtitle {
        font-size: 1.2rem;
        color: white;
      }
    }
  }
}

.container-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 2rem;
  letter-spacing: 0.01rem;
  line-height: 1.5rem;

  .content {
    width: 1300px;
    display: flex;
    flex-direction: column;

    font-size: 1.2rem;

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }
  }
}
