.image-wave-bottom {
  position: relative;
  bottom: 0px;
  background-color: white;
}

.image-wave-top {
  position: relative;
  top: 0px;
  background-color: white;
}

.image-wave-bottom-abs {
  position: absolute;
  bottom: 0px;
  background-color: white;
}
