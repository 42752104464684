@import "../../../styles/colors.scss";

.default-class-about {
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    width: 1300px;
    display: flex;
    gap: 3rem;
    padding: 1rem 1rem;

    @media only screen and (max-width: 1300px) {
      flex-direction: column;
      gap: 1rem;
    }

    .content-text {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media only screen and (max-width: 1300px) {
        width: 100%;
      }

      .title {
        color: #942222;
        font-size: 2.2rem;
        font-weight: 700;
      }

      .description {
        justify-content: center;
        letter-spacing: 0.01rem;
        line-height: 1.5rem;
        font-size: 1.2rem;
      }
    }

    .content-img {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 1300px) {
        width: 100%;
        justify-content: center;
      }

      .image {
        height: 26rem;

        @media only screen and (max-width: 500px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
