@import "../../../styles/colors.scss";

.default-class-hardware {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .container {
    width: 1300px;
    display: flex;
    padding: 1rem 1rem;
    gap: 3rem;

    @media only screen and (max-width: 1300px) {
      flex-direction: column;
      width: 100%;
    }

    .content-text {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      @media only screen and (max-width: 1300px) {
        width: 100%;
      }

      .title {
        color: #942222;
        font-size: 2.2rem;
        font-weight: 700;
      }

      .description {
        justify-content: center;
        letter-spacing: 0.01rem;
        line-height: 1.5rem;
        font-size: 1.2rem;
      }
    }

    .content-model {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media only screen and (max-width: 1300px) {
        width: 100%;
      }

      .model-3d {
        width: 100%;
        height: 28rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 1300px) {
          height: 23rem;
        }
      }
    }
  }

  .container-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 1rem;

    .card {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 1.5rem;
      width: 19rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media only screen and (max-width: 650px) {
        width: 100%;
      }

      .icon {
        width: 60px;
        height: 60px;
        background-color: #942222;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        color: white;
      }

      .title {
        color: black;
        font-weight: bold;
        font-size: 1.1rem;
      }

      .description {
        color: black;
      }
    }
  }
}
