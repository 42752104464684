@import "../../../styles/colors.scss";

.default-class-services {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $gradient;

  .container {
    width: 1300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 1rem;
    gap: 2rem;

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }

    .content-title {
      width: 100%;

      .title {
        font-weight: bold;
        font-size: 2.2rem;
        color: white;
      }
    }

    .content-card {
      width: 1300px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1.2rem;

      @media only screen and (max-width: 1300px) {
        width: 100%;
      }

      .card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: white;
        border-radius: 10px;
        width: 25.5rem;
        padding: 1rem;

        .container-img {
          width: 100%;
          min-height: 18rem;
          display: flex;
          align-items: center;
          justify-content: center;

          @media only screen and (max-width: 550px) {
            width: 100%;
          }

          .image {
            width: 18rem;

            @media only screen and (max-width: 550px) {
              width: 100%;
            }
          }
        }

        .title {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 1.2rem;
          color: #942222;
          padding: 1rem 0rem;
          text-align: center;
        }

        .description {
          letter-spacing: 0.01rem;
          line-height: 1.5rem;
          text-align: justify;
        }
      }
    }
  }
}
