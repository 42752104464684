@import "../../../styles/colors.scss";

.default-footer-class {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $gradient;

  .container {
    width: 1300px;
    display: flex;

    justify-content: space-between;
    padding: 1rem 1rem;

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
      gap: 2rem;
    }

    .content-image {
      display: flex;
      align-items: center;
      justify-content: center;

      .image {
        width: 17rem;
        height: 5rem;
        background-image: url("../../../assets/intuitiva/logo_intuitiva_com_nome_branco.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        cursor: pointer;

        @media only screen and (max-width: 300px) {
          width: 5rem;
          height: 5rem;
          background-image: url("../../../assets/intuitiva/logo_intuitiva_sem_nome_branco.png");
        }
      }
    }

    .content-info {
      color: white;
      font-size: 1.2rem;
      display: flex;
      flex-direction: column;
      gap: 0.7rem;

      @media only screen and (max-width: 1000px) {
        align-items: center;
        text-align: center;
      }

      .title {
        padding: 0.5rem 0rem;
        font-weight: bold;
      }

      .container-menu {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }

  .line-division {
    height: 1px;
    width: 1270px;
    background-color: white;
    margin: 1rem 0rem 2rem 0rem;

    @media only screen and (max-width: 1300px) {
      width: calc(100% - 4rem);
    }
  }

  .container-copy {
    width: 1300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 0rem 1rem 2rem 1rem;
    font-size: 1.1rem;
    cursor: pointer;

    @media only screen and (max-width: 1300px) {
      width: 100%;
      padding: 0rem 2rem 2rem 2rem;
    }

    @media only screen and (max-width: 450px) {
      flex-direction: column;
      gap: 1rem;
    }
  }
}
