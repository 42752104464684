@import "../../../styles/colors.scss";

.container-home {
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 98vh;
  background: $gradient;

  .content {
    width: 1300px;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 5rem;

      .image {
        width: 17rem;
        height: 5rem;
        background-image: url("../../../assets/intuitiva/logo_intuitiva_com_nome_branco.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        @media only screen and (max-width: 1300px) {
          width: 5rem;
          height: 5rem;
          background-image: url("../../../assets/intuitiva/logo_intuitiva_sem_nome_branco.png");
        }
      }

      .options {
        display: flex;
        gap: 1rem;
        color: white;
        font-size: 1.2rem;
        font-weight: 600;
        cursor: pointer;

        @media only screen and (max-width: 1300px) {
          display: none;
        }
      }
    }

    .container {
      display: flex;
      min-height: 80%;

      .container-title {
        width: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;

        @media only screen and (max-width: 1300px) {
          width: 100%;
          padding: 2rem 0rem;
        }

        .title {
          font-size: 2.8rem;
          color: white;
          font-weight: 600;
          line-height: 1.25;

          @media only screen and (max-width: 1300px) {
            font-size: 1.8rem;
          }
        }

        .subtitle {
          font-size: 1.125rem;
          color: white;
          line-height: 1.5rem;
        }

        .button {
          max-width: 19rem;
          background-color: white;
          color: #942222;
        }
      }
    }

    .container-ilustrador {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media only screen and (max-width: 1300px) {
        display: none;
      }

      .image {
        width: 500px;
      }
    }
  }
}
