@import "../../../styles/colors.scss";

.default-class-contact {
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    width: 1300px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    padding: 1rem 1rem;

    @media only screen and (max-width: 1300px) {
      flex-direction: column;
    }

    .content-illustration {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 1300px) {
        width: 100%;
      }

      .image {
        @media only screen and (max-width: 500px) {
          width: 100%;
        }
      }
    }

    .content-form {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media only screen and (max-width: 1300px) {
        width: 100%;
      }

      .title {
        font-size: 2rem;
        font-weight: bold;
        color: #942222;
      }

      .subtitle {
        letter-spacing: 0.01rem;
        line-height: 1.5rem;
        font-size: 1.2rem;
      }

      .input {
        width: 100%;

        span {
          .input {
            width: 100%;
          }
        }
      }
    }
  }
}

.p-message {
  margin: 0 !important;
}
