.back-to-top {
  position: fixed;
  bottom: 6rem;
  right: 1.3rem;
  z-index: 12;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  column-gap: 0.5rem;

  transition: opacity 0.5s ease-out;
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-in-out;

  .back-to-top-button {
    z-index: 12;

    width: 3rem;
    height: 3rem;

    background-color: #942222;

    border-radius: 100%;

    border: 2px solid white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    svg {
      display: flex;
      color: white;
      font-size: 2.2rem;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
